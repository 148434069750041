/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from 'react';
import './StringFancyDisplay.css';
import { Grow } from '@mui/material';

interface StringFancyDisplayProps {
  text: string;
  speed?: number;
  endText?: string;
  endFlash?: boolean;
  isDone?: ( isDone: boolean ) => void;
}

function StringFancyDisplay( {
    text,
    speed = 100,
    endText,
    endFlash,
    isDone,
}: StringFancyDisplayProps ) {
    const [displayedText, setDisplayedText] = useState<string>( '' );
    const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>( null ); // Use useRef here
    const [animationFinished, setAnimationFinished] = useState<boolean>( false );

    useEffect( () => {
        if ( displayedText.length === 0 ) {
            intervalIdRef.current = setInterval( () => {
                setDisplayedText( ( prevText ) => {
                    const nextChar = text.charAt( prevText.length );
                    if ( nextChar ) {
                        return prevText + nextChar;
                    }
                    if ( intervalIdRef.current ) {
                        clearInterval( intervalIdRef.current );
                        setAnimationFinished( true );
                    }

                    return prevText;
                } );
            }, speed );
        }

        return () => {
            if ( intervalIdRef.current ) {
                clearInterval( intervalIdRef.current );
            }
        };
    }, [text] );

    useEffect( () => {
        if ( isDone ) {
            isDone( true );
        }
    }, [animationFinished] );

    return (
        <span className={ `typingEffect ${animationFinished && endFlash ? 'flashEffect' : ''}` }>
            { displayedText }
            <span className="cursor">|</span>
            { endText && animationFinished
              && (
              <Grow in={ animationFinished } timeout={ 1000 }>
                  <span
                    style={
                        {
                            fontStyle: 'italic',
                            fontSize: '1.2rem',
                            fontWeight: '100!important',
                            marginBottom: '5rem',
                        }
                    }>
                      { endText }
                  </span>
              </Grow>
) }
        </span>
    );
}

export default StringFancyDisplay;
