/* eslint-disable import/no-unresolved */
import React from 'react';
import { IMemberCardProps } from 'TeamViewer';
import MobileContext from '../../../contexts/MobileContext';
import config from '../../lib/config.json';
import './MemberCard.css';

const { team } = config;
const { maxNumberOfGames } = team;

export function MemberCard( { teamMember, maxWidth }: IMemberCardProps ) {
    const { isMobile } = React.useContext( MobileContext );

    function getRandomGames( games:string[], max:number ) {
        const shuffled = games.sort( () => 0.5 - Math.random() );
        return shuffled.slice( 0, max );
    }

    return (
        <div
          style={
              {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: '20px',
                  padding: '2vh 0.5%',
                  width: isMobile ? '90%' : '30%',
                  maxWidth: isMobile ? '90%' : maxWidth || 300,
                  background: 'linear-gradient(180deg, #907DFF 0%, #722BFF 100%)',
                  boxShadow: '0px 20px 40px 0px #00000040',
                  backgroundPosition: '50% 50%',
                  minHeight: isMobile ? undefined : '425px',
                }
          }
          className="teamviewer__map__member img-shadow noselect"
          key={ teamMember.name }
            >
            <div
              style={
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }
            }
              className="teamviewer__map__member__wrapper"
            >
                <a
                  href={ teamMember.linkedIn }
                  target="_blank"
                  rel="noreferrer"
                  className="teamviewer__map__member__wrapper__image"
                >
                    <div
                      style={ {
                        padding: '5px',
                        borderRadius: '50%',
                        display: 'flex',
                        position: 'relative',
                    } }>
                        <img
                          style={ {
                            width: maxWidth ? maxWidth / 2 : 150,
                            height: maxWidth ? maxWidth / 2 : 150,
                            borderRadius: '50%',
                            position: 'relative',
                            filter: 'sepia(1) hue-rotate(222deg) brightness(0.9)',
                          } }
                          src={ `images/Team/Employees/${teamMember.photo}.webp` }
                          alt={ teamMember.name }
                        />
                    </div>
                </a>
                <h4
                  style={ {
                    margin: '1.5rem 0 0 0',
                  } }
                  className="aligncenter">
                    { teamMember.name }
                </h4>
                <span
                  style={ {
                    color: '#fff',
                    opacity: 0.6,
                } }
                  className="aligncenter"
                >
                    { teamMember.title }

                </span>
                {
                  teamMember.favoriteGames && teamMember.favoriteGames.length > 0 && (
                  <div style={ { textAlign: 'center' } }>
                      <h5 style={ { margin: '10px 0 0 0', color: '#fff' } }>Favorite Games</h5>
                      <ul style={ {
                            listStyleType: 'none',
                            paddingLeft: 0,
                            textAlign: 'center',
                            margin: 0,
                        } }>
                          { getRandomGames( teamMember.favoriteGames, maxNumberOfGames ).sort( ( a, b ) => a.length - b.length ).map( ( game ) => (
                              <li
                                key={ game }
                                style={ {
                                  margin: '0',
                                  color: '#fff',
                                  opacity: 0.6,
                                  fontSize: '0.9rem',
                                  textAlign: 'center',
                                  lineHeight: '1.2rem',
                                } }>
                                  { game }
                              </li>
                            ) ) }
                      </ul>
                  </div>
                  )
                }
            </div>
        </div>
    );
}
