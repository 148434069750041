import React, { useEffect, useState, useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/material';
import { Logo } from '../../atoms/Logo';
import { NavbarMenus } from '../../molecules/NavbarMenus/NavbarMenus';
import menus from '../../lib/nav-menus.json';
import config from '../../lib/config.json';
import MobileContext from '../../../contexts/MobileContext';
import NavbarContext from '../../../contexts/NavbarContext';
import BackButton from '../../molecules/Button/BackButton';
import theme from '../../themes/default/theme';

const { topNavHome } = menus;
const { navbar } = config;

export function NavigationBar() {
    const { isMobile } = useContext( MobileContext );

    const [scrolled, setScrolled] = useState( false );
    const { mobileMenuOpen, setMobileMenuOpen, shouldShowNavigationBar } = useContext( NavbarContext );

    useEffect( () => {
        const handleScroll = () => {
            setScrolled( window.scrollY > 10 );
        };

        window.addEventListener( 'scroll', handleScroll );

        return () => {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [] );

    const navBarStyle = (): React.CSSProperties => {
        let background;

        if ( isMobile && mobileMenuOpen ) {
            background = 'transparent';
        } else if ( scrolled ) {
            background = '#fff';
        } else {
            background = '#fff';
        }

        return {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: navbar.height,
            width: '100%',
            background,
            position: 'sticky',
            top: 0,
            zIndex: 100,
        };
    };

    return (
        <div
          className="navigationbar__container noselect"
          style={ navBarStyle() }
            >
            <div
              className="navigationbar__wrapper"
              style={ {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
                width: '95%',
                position: 'relative',
            } }>
                { !mobileMenuOpen && <Logo width="290px" link="/" /> }
                { isMobile && shouldShowNavigationBar && (
                    <>
                        <Button
                          sx={
                            {
                                background: 'transparent',
                                width: 'fit-content',
                            }
                          }
                          type="button"
                          onClick={ () => setMobileMenuOpen( ( prev ) => !prev ) }>
                            { !mobileMenuOpen && (
                            <MenuIcon />
                                ) }
                        </Button>
                        { mobileMenuOpen && (
                            <div className="mobileMenu">
                                <NavbarMenus menus={ topNavHome } />
                            </div>
                        ) }
                    </>
                ) }
                { !isMobile && shouldShowNavigationBar && (
                    <NavbarMenus menus={ topNavHome } />
                ) }
                {
                    !shouldShowNavigationBar && (
                        <BackButton
                          onClick={ () => {
                                window.history.back();
                            } }
                          style={ {
                            // background: 'transparent',
                            width: '150px',
                            color: '#fff',
                            // boxShadow: 'none',
                        } }
                        />
                    )
                }
            </div>
        </div>
    );
}
