import React from 'react';
import { Container } from '@mui/material';
import partners from '../../../lib/partners.json';
import './Partners.css';
import MobileContext from '../../../../contexts/MobileContext';
import { PartnerTile } from '../../../organisms/PartnerTile/PartnerTile';
import copies from '../../../lib/copies.json';
import { SectionHeader } from '../../../atoms/SectionHeader';

const partnersCopies = copies.partners;

export function Partners() {
    const { isMobile } = React.useContext( MobileContext );
    return (
        <div
          className="partners__container"
          id="partners"
          style={ {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: isMobile ? '75%' : '90%',
            position: 'relative',
            maxWidth: '100%',
            margin: isMobile ? '0 0 10vh 0' : '5vh 0 10vh 0',
        } }
            >
            <SectionHeader
              textStyle={
                {
                  color: '#fff',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: isMobile ? '2rem' : '3rem',
                }
              }
            >
                { partnersCopies.header }
            </SectionHeader>

            <Container
              sx={ {
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: isMobile ? 'wrap' : 'initial',
                gap: '4rem',
                justifyContent: 'center',
                alignItems: 'center',
            } }
              className="partners__wrapper">
                { partners.map( ( partner ) => (
                    <PartnerTile
                      key={ partner.name }
                      partner={ partner }
                                />
                        ) ) }
            </Container>
        </div>
    );
}
