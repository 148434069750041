import { Container } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import copies from '../../../lib/copies.json';
import config from '../../../lib/config.json';

import './Mission.css';
import MobileContext from '../../../../contexts/MobileContext';
import { SectionHeader } from '../../../atoms/SectionHeader';

const { sections } = config;
const { missionSection } = sections;
const { mission } = copies;

export function Mission() {
    const { isMobile } = React.useContext( MobileContext );

    return (
        <div
          id="mission"
          style={
                {
                    width: '100%',
                    padding: isMobile ? '5vh 0 5vh 0' : '10vh 0 10vh 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(180deg, #FFFFFF 0%, #DDCCFF 100%)',
                }
            }
            >
            <Container
              maxWidth={ false }
              sx={
                        {
                            height: '100%',
                            maxWidth: isMobile ? '90%' : '70%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            flex: 1,
                        }
                    }
              className="mission__wrapper"
                >
                <div
                  style={
                    {
                        height: '100%',
                        width: isMobile ? '100%' : '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                    }
                }
                  className="mission__container"
             >
                    <SectionHeader
                      textStyle={
                            {
                                textAlign: 'left',
                            }
                        }
                    >
                        { mission.header }
                    </SectionHeader>

                    <ReactMarkdown remarkPlugins={ [remarkGfm] }>
                        { mission.paragraph }
                    </ReactMarkdown>
                </div>
                <div
                  className="mission__img__container"
                  style={
                    {
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                    }
                }
                >
                    <div
                      style={
                            {
                            display: 'inline-block',
                            padding: '3px',
                            background: 'linear-gradient(180deg, rgba(140, 82, 255, 0.2) 0%, rgba(140, 82, 255, 0.4) 100%)',
                            borderRadius: '33px',
                            // width: '100%',
                            boxShadow: '0px 20px 40px 0px #00000066',
                            }
                        }
                        >
                        <img
                          src="/images/Mission/mission.webp"
                          width={ isMobile ? 'calc(90% - 6px)' : '394px' }
                          alt="mission"
                          style={
                            {
                                borderRadius: '30px',
                                display: 'block',
                                maxWidth: '100%',
                            }
                          }
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
}
