import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { smoothScrollTo } from '../lib/utils/smoothScrollTo';
import NavbarContext from '../../contexts/NavbarContext';
import MobileContext from '../../contexts/MobileContext';

export function NavbarMenu( { name, link }: { name: string; link:string } ) {
    const [clicked, setClicked] = useState( false );
    const { mobileMenuOpen, setMobileMenuOpen } = React.useContext( NavbarContext );
    const [isActive, setIsActive] = useState( false );
    const { isMobile } = React.useContext( MobileContext );

    const theme = useTheme();

    const onClick = ( () => {
        setClicked( !clicked );
    } );

    useEffect( () => {
        if ( clicked && mobileMenuOpen ) {
            setMobileMenuOpen( false );
        }
    }, [clicked] );

    useEffect( () => {
        const linkIsExternal = ( url:string ) => {
            try {
                const linkDomain = new URL( url ).hostname;
                const currentDomain = window.location.hostname;
                return currentDomain !== linkDomain;
            } catch ( e ) {
                return false;
            }
        };

        if ( clicked ) {
            if ( link.startsWith( '#' ) || link.startsWith( '/#' ) ) {
                // Handle internal anchor links...
                const sectionId = link.replace( '/#', '' ).replace( '#', '' );
                const section = document.getElementById( sectionId );
                if ( section ) {
                    const sectionMiddle = section.offsetTop + ( section.offsetHeight / 2 );
                    const windowMiddle = window.innerHeight / 2;
                    const scrollPosition = sectionMiddle - windowMiddle;

                    smoothScrollTo( document.documentElement, window.scrollY, scrollPosition, 1000 );

                    history.pushState( null, ' ', ' ' );
                }
            } else if ( linkIsExternal( link ) ) {
                window.open( link, '_blank' );
            } else {
                window.location.href = link;
            }
        }
        // Reset the clicked state
        setClicked( false );
    }, [clicked] );

    useEffect( () => {
        const checkActiveSection = () => {
            if ( link.startsWith( '#' ) || link.startsWith( '/#' ) ) {
                const sectionId = link.replace( '/#', '' ).replace( '#', '' );
                const section = document.getElementById( sectionId );
                if ( section ) {
                    const sectionTop = section.offsetTop;
                    const sectionBottom = section.offsetTop + section.offsetHeight;

                    const threshold = 0.7;
                    const visibleSectionHeight = Math.min( window.scrollY + window.innerHeight, sectionBottom ) - Math.max( window.scrollY, sectionTop );

                    if ( visibleSectionHeight >= threshold * section.offsetHeight ) {
                        setIsActive( true );
                    } else {
                        setIsActive( false );
                    }
                }
            }
        };

        window.addEventListener( 'scroll', checkActiveSection );

        return () => {
            window.removeEventListener( 'scroll', checkActiveSection );
        };
    }, [] );

    return (
        <Button
          color="primary"
          onClick={ onClick }
          fullWidth
          sx={
            {
                width: '100%',
                height: '100%',
                fontWeight: isActive ? 'bold' : '200',
                background: isActive ? theme.palette.primary.main : 'transparent',
            }
          }
            >
            <p
              style={
                    {
                        color: isActive || isMobile ? '#fff' : theme.palette.primary.main,
                    }
                }
             >
                { `${name.slice( 0, 1 ).toUpperCase()}${name.slice( 1 ).toLowerCase()}` }
            </p>
        </Button>
    );
}
