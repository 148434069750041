/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import useResizeObserver from 'use-resize-observer';
import { ITeamViewerProps, IEmployee } from 'TeamViewer';
import { MemberCard } from '../../molecules/MemberCard/MemberCard';
import './TeamViewer.css';
import MobileContext from '../../../contexts/MobileContext';
import config from '../../lib/config.json';
import { SectionHeader } from '../../atoms/SectionHeader';
import copies from '../../lib/copies.json';

const { team } = config;
const { ourTeam } = copies;
const { isCollapsed, topMembers, numberToShow } = team;

export function TeamViewer( { short, expanded, alumni }: ITeamViewerProps ) {
    const [collapsed, setCollapsed] = useState( isCollapsed );
    const { ref: resizeObserverRef, height: contentHeight } = useResizeObserver();
    const { isMobile } = React.useContext( MobileContext );
    const [finalShort, setFinalShort] = useState<IEmployee[]>( [] );
    const [finalExpanded, setFinalExpanded] = useState<IEmployee[]>( [] );
    const [finalNumberToShow, setFinalNumberToShow] = useState<number>( numberToShow || 4 );

    const animationProps = useSpring( {
        height: collapsed ? '0px' : `${contentHeight ? contentHeight + 20 : 0}px`,
        opacity: collapsed ? 0 : 1,
        config: { tension: 280, friction: 60 },
        overflow: 'hidden',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: isMobile ? '90%' : '100%',
        marginTop: '1rem',
    } );

    useEffect( () => {
        if ( topMembers === 'random' ) {
            const arrayOfMembers = [...short, ...expanded];

            const randomMembers = [];

            while ( arrayOfMembers.length ) {
                const randomIndex = Math.floor( Math.random() * arrayOfMembers.length );
                randomMembers.push( arrayOfMembers.splice( randomIndex, 1 )[0] );
            }

            const newFinalShort = randomMembers.slice( 0, finalNumberToShow || 4 );
            const newFinalExpanded = randomMembers.slice( finalNumberToShow || 4, [...short, ...expanded].length );

            setFinalShort( newFinalShort );
            setFinalExpanded( newFinalExpanded );
        } else {
            setFinalShort( short );
            setFinalExpanded( expanded );
        }
    }, [finalNumberToShow] );

    useEffect( () => {
        if ( isMobile && numberToShow && numberToShow > 3 ) {
            setFinalNumberToShow( 3 );
        } else {
            setFinalNumberToShow( numberToShow || 4 );
        }
    }, [isMobile] );

    return (
        <div
          style={
            {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                padding: '5vh 0 5vh 0',
            }
        }
          className="teamviewer__container"
            >
            <Container
              maxWidth="xl"
              sx={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        // gap: '5vh',
                    }
                }
            >
                <SectionHeader
                  textColor="#fff"
                  style={
                        {
                          width: isMobile ? '75%' : '90%',
                        }
                      }
                  textStyle={
                        {
                          color: '#fff',
                          textAlign: isMobile ? 'center' : 'center',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          fontSize: isMobile ? '2rem' : '3rem',
                          fontFamily: '"Montserrat", sans-serif',
                        }
                      }
                    >
                    { ourTeam.header }
                </SectionHeader>
                <div
                  style={
                    {
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: isMobile ? '90%' : '90%',
                        maxWidth: isMobile ? '90%' : '100%',
                        height: '100%',
                        WebkitFlexWrap: 'wrap',
                        rowGap: '1.5rem',
                    }
                }
                  className="teamviewer__map"
                >
                    { finalShort.map( ( teamMember: IEmployee ) => (
                        <MemberCard key={ teamMember.name } teamMember={ teamMember } />
                ) ) }
                </div>
                {
                finalExpanded && finalExpanded.length > 0 && (
                    <>
                        <animated.div
                          style={ animationProps }
                          className="teamviewer__container__animated"
                        >
                            <div
                              ref={ resizeObserverRef }
                              style={
                                {
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                }
                              }
                              className="teamviewer__map__animated"
                            >
                                <div
                                  style={
                                        {
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                            width: isMobile ? '100%' : '90%',
                                            height: '100%',
                                            WebkitFlexWrap: 'wrap',
                                            rowGap: '1.5rem',
                                        }
                                    }
                                  className="teamviewer__map__animated"
                                    >
                                    { finalExpanded.map( ( teamMember: IEmployee ) => (
                                        <MemberCard key={ teamMember.name } teamMember={ teamMember } />
                                    ) ) }
                                </div>
                            </div>
                        </animated.div>
                        <div
                          onClick={ () => setCollapsed( !collapsed ) }
                          tabIndex={ 0 }
                          role="button"
                          className="teamviewer__expand__view__cta noselect"
                          >
                            { collapsed ? <span>Expand View ↓</span> : <span>Collapse View ↑</span> }
                        </div>
                    </>
                )
            }
                <h2
                  style={
                    {
                      color: '#fff',
                      opacity: 0.6,
                      // fontSize: '0.9rem',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontFamily: '"Montserrat", sans-serif',
                    }
                  }
                >
                    { ourTeam.subtitle }
                </h2>
                <div
                  style={
                    {
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: isMobile ? '90%' : '90%',
                        maxWidth: isMobile ? '90%' : '100%',
                        height: '100%',
                        WebkitFlexWrap: 'wrap',
                        rowGap: '1.5rem',
                    }
                  }
                  className="teamviewer__map"
                  >
                    { alumni.map( ( teamMember: IEmployee ) => (
                        <MemberCard key={ teamMember.name } teamMember={ teamMember } />
                    ) ) }
                </div>
            </Container>
        </div>
    );
}
