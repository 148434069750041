import React, { Fragment } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { SocialIcons } from '../../molecules/SocialIcons';
import config from '../../lib/config.json';

export function Footer() {
    const theme = useTheme();

    return (
        <div
          style={
            {
                padding: '3vh 0px 3vh 0px',
                background: 'linear-gradient(180deg, #FFFFFF 0%, #F0E9FF 100%)',
            }
        }
          className="footer__container"
            >
            <div className="footer__social__icons__container">
                <div className="footer__social__icons">
                    <SocialIcons icons={ config.footer.socialIcons } heightComponent="5vh" />
                </div>
                <div
                  style={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: '5vh',
                        fontSize: '0.8rem',
                    }
                }
                  className="footer__links">
                    { config.footer.links.map( ( link, index ) => (
                        <Fragment
                          key={ link.name }
                        >
                            <a
                              key={ link.name }
                              href={ link.url }
                              style={
                            {
                                textTransform: 'uppercase',
                                color: theme.palette.primary.main,
                            }
                          }
                              className="footer__link"
                        >
                                { link.name }
                            </a>
                            { index !== config.footer.links.length - 1 && (
                            <span style={ { color: '#ffffff' } } className="footer__link-separator">&nbsp;|&nbsp;</span>
                    ) }
                        </Fragment>
            ) ) }
                </div>
                <div
                  style={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '5vh',
                        fontSize: '0.8rem',
                    }
                }
                  className="footer__copyright">
                    <span style={ { color: theme.palette.primary.main, fontWeight: 'bold' } }>&copy; 2023 Harmony Games</span>
                </div>
            </div>
        </div>
    );
}
