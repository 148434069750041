import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IBackButtonProps {
    onClick: () => void;
    style?: React.CSSProperties;
}

function BackButton( { onClick, style }: IBackButtonProps ) {
    return (
        <Button
          variant="contained"
          color="primary"
          style={ style }
          startIcon={ <ArrowBackIcon /> }
          onClick={ onClick }
            >
            Back
        </Button>
    );
}

export default BackButton;
