import React from 'react';
import { NavbarMenu } from '../../atoms/NavbarMenu';
import config from '../../lib/config.json';
import MobileContext from '../../../contexts/MobileContext';
import './NavbarMenus.css';

export function NavbarMenus( { menus }: { menus: { name: string; link: string }[] } ) {
    const { isMobile } = React.useContext( MobileContext );

    const menusContainerStyle = (): React.CSSProperties => ( {
        height: isMobile ? '100%' : config.navbar.height,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        background: isMobile ? 'linear-gradient(45deg, rgba(57, 25, 120, 0.9) 0%, rgba(94, 23, 235, 0.9) 100%)' : 'transparent',
        width: '100%',
        right: isMobile ? '0' : undefined,
        top: isMobile ? '0' : undefined,
        position: isMobile ? 'fixed' : 'relative',
    } );

    return (
        <div
          style={ menusContainerStyle() }
          className="menu__container"
            >
            { menus.map( ( menu ) => (
                <div
                  key={ menu.name }
                  style={
                    {
                        height: isMobile ? 'fit-content' : config.navbar.height,
                    }
                  }
                  className="menu__item"
                >
                    <NavbarMenu name={ menu.name } link={ menu.link } />
                </div>
            ) ) }
        </div>
    );
}
