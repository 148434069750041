/* eslint-disable import/no-unresolved */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IAmazingSliderProps } from 'AmazingSlider';

import { Autoplay, Navigation, Pagination } from 'swiper';

import sliders from '../../lib/sliders.json';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import config from '../../lib/config.json';

import './AmazingSlider.css';
import { Slide } from '../../molecules/Slide/Slide';
import PunContext from '../../../contexts/PunContext';

const { hero } = sliders;
const heroSection = config.hero;

export function AmazingSlider( props: IAmazingSliderProps ) {
    const { autoPlay = heroSection.slider.autoplay, slides = hero } = props;
    const { punsAlreadyVoted, setPunsAlreadyVoted } = React.useContext( PunContext );

    const onVoteClick = ( index: number ) => {
        // NOOP
    };

    return (
        <Swiper
          spaceBetween={ 0 }
          slidesPerView={ 1 }
          direction="horizontal"
          style={ { width: '100%', height: '100%' } }
          loop
          autoplay={ autoPlay
            ? {
                delay: heroSection.slider.autoplaySpeed || 10000,
                disableOnInteraction: false,
            }
            : false }
          pagination={ {
                dynamicBullets: true,
                clickable: true,
            } }
          modules={ [Autoplay, Navigation, Pagination] }
            >
            { slides.map( ( slide ) => (
                <SwiperSlide key={ `${slide.title}${slide.type}` } style={ { width: '100%', maxHeight: '80vh' } }>
                    <Slide onVoteClick={ onVoteClick } slide={ slide } />
                </SwiperSlide>
                ) ) }
        </Swiper>
    );
}
