import React from 'react';

export function Logo( { width, link }: { width: string; link:string } ) {
    return (
        <div
          className="logo__container noselect"
            >
            <a href={ link || '' }>
                <img width={ width } src="/images/logo.webp" alt="" className="noselect" />
            </a>
        </div>
    );
}
