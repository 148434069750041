/* eslint-disable import/no-unresolved */
import React, { CSSProperties, useEffect } from 'react';
import { ISystemPageProps } from 'SystemPages';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Container } from '@mui/material';
import tos from '../../lib/terms-of-service.json';
import cookiePolicy from '../../lib/cookie-policy.json';
import privacyPolicy from '../../lib/privacy-policy.json';

import './SystemPage.css';

export function SystemPage( { type, style }: ISystemPageProps ) {
    const [pageCopies, setPageCopies] = React.useState( tos );

    useEffect( () => {
        switch ( type ) {
        case 'cookie-notice':
            setPageCopies( cookiePolicy );
            break;
        case 'privacy-policy':
            setPageCopies( privacyPolicy );

            break;
        case 'terms-of-service':
            setPageCopies( tos );

            break;
        default:
            break;
        }
    }, [type] );

    return (
        <div
          style={ {
            ...style,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '100%',
            textAlign: 'left',
            padding: '5rem 0',
            background: 'linear-gradient(180deg, #FFFFFF 0%, #DDCCFF 100%)',
        } }
          className="system-page__container"
            >
            <Container
              maxWidth="xl"
              sx={
                {
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'left',
                    borderRadius: '25px',
                    width: '90%!important',
                }
              }
            >
                { /* <h1>{ pageCopies?.header }</h1> */ }
                <ReactMarkdown remarkPlugins={ [remarkGfm] }>
                    { pageCopies?.content }
                </ReactMarkdown>
            </Container>
        </div>
    );
}
