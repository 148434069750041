/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IAboutUsTileProps } from 'AboutUsTile';
import MobileContext from '../../../contexts/MobileContext';
import config from '../../lib/config.json';
import './AboutUsTile.css';

const { sections } = config;
const { aboutUsSection } = sections;
const { characterLimit } = aboutUsSection;

export function AboutUsTile( {
    header,
    paragraph,
    image,
    expandable,
    expandDirection,
    index,
    style,
}: IAboutUsTileProps ) {
    const { isMobile } = React.useContext( MobileContext );
    const [finalExpandDirection, setFinalExpandDirection] = React.useState<'top' | 'bottom'>( expandDirection || 'bottom' );
    const [expanded, setExpanded] = React.useState( !!( !isMobile || !expandable ) );

    const contentRef = useRef<HTMLDivElement>( null );

    const expand = ( event: React.MouseEvent<HTMLDivElement> ) => {
        if ( expandable ) {
            setExpanded( !expanded );
        }
    };

    useEffect( () => {
        if ( contentRef && contentRef.current ) {
            if ( expanded ) {
                const contentHeight = contentRef.current.scrollHeight;
                contentRef.current.style.maxHeight = `${contentHeight}px`;
            } else {
                contentRef.current.style.maxHeight = '300px';
            }
        }
    }, [expanded] );

    useEffect( () => {
        if ( isMobile ) {
            setFinalExpandDirection( 'bottom' );
        }
    }, [isMobile] );

    return (
        <div
          tabIndex={ 0 }
          key={ header }
          role="button"
          className="noselect aboutus__tile__container"
          style={ {
                ...style,
                position: 'relative',
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(240, 233, 255, 0.8) 100%)',
                boxShadow: '0px 20px 40px 0px #00000040',
            } }
          onClick={ expand }
            >
            { index
            && (
                <div
                  className="about-us__index__tile"
                  style={
                    {
                        position: 'absolute',
                        top: '-1rem',
                        left: '-1.5rem',
                        fontSize: '2rem',
                        background: 'linear-gradient(180deg, #A476FF 0%, #7345D0 100%)',
                        color: 'white',
                        width: '3rem',
                        borderRadius: '50%',
                        fontFamily: '"Montserrat", sans-serif',
                        fontWeight: 'bold',
                    }
                  }
                >
                    { index }
                </div>
            ) }
            <h3
              style={
                    {
                        fontFamily: '"Montserrat", sans-serif',
                        fontWeight: 'bold',
                        marginBottom: 0,
                    }
                }
            >
                { header }

            </h3>
            <div
              className={ `expandable-content ${expanded ? 'expanded' : ''}` }
              ref={ contentRef }>
                <ReactMarkdown remarkPlugins={ [remarkGfm] }>
                    { expanded ? paragraph : `${paragraph.slice( 0, characterLimit )}...` }
                </ReactMarkdown>
            </div>
            { expandable
                && (
                    <div
                      aria-label="Expand or collapse content"
                      className={ `expand-arrow ${expanded ? 'expanded' : ''}` }
                    />
                ) }
        </div>
    );
}
