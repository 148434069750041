/* eslint-disable import/no-unresolved */
import React from 'react';
import { INavbarContext } from 'NavbarContext';

const NavbarContext = React.createContext<INavbarContext>( {
    mobileMenuOpen: false,
    setMobileMenuOpen: () => {},
    shouldShowNavigationBar: false,
} );

export default NavbarContext;
