/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button } from '@mui/material';
import { IHarmonyCtaProps } from 'HarmonyCta';

export function HarmonyCta( { link, text, width }: IHarmonyCtaProps ) {
    return (
        <div className="harmony__cta__container">
            <a href={ link } target="_blank" rel="noreferrer">
                <Button
                  sx={
                    {
                        background: 'linear-gradient(180deg, #A476FF 0%, #7345D0 100%)',
                        borderRadius: 5,
                        border: 0,
                        color: 'white',
                        height: 48,
                        width: width || '100%',
                        padding: '30px 30px',
                        boxShadow: '0px 20px 40px 0px #00000040',
                        '&:hover': {
                            background: 'linear-gradient(90deg, #8152FF 100%, #5E17EB 100%)',
                            // boxShadow: '0px 20px 40px 0px #00000040',
                        },
                    }
                }
            >
                    <span
                      style={
                            {
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                                fontWeight: '900',
                                fontSize: '1.5rem',
                                lineHeight: '1.5rem',
                                letterSpacing: '0.01em',
                                color: '#FFFFFF',
                                textTransform: 'none',
                            }
                        }
                    >
                        { text }

                    </span>
                </Button>
            </a>
        </div>
    );
}
