/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable import/no-unresolved */
import { Grow } from '@mui/material';
import { ISlideProps } from 'AmazingSlider';
import { type } from 'os';
import React, { useEffect, useState, CSSProperties } from 'react';
import { SwiperSlide } from 'swiper/react';

import config from '../../lib/config.json';
import './Slide.css';
import StringFancyDisplay from '../../lib/utils/StringFancyDisplay/StringFancyDisplay';
import MobileContext from '../../../contexts/MobileContext';
import PunContext from '../../../contexts/PunContext';

const TIMER = config.hero.timerVote;

export function Slide( { slide, onVoteClick }: ISlideProps ) {
    const [currentPuns, setCurrentPuns] = React.useState<string[]>( [] );
    const [punIndex, setPunIndex] = useState( 0 );
    const [thanks, setThanks] = useState( false );

    const { isMobile } = React.useContext( MobileContext );
    const { punsAlreadyVoted, setPunsAlreadyVoted } = React.useContext( PunContext );
    const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>( null );

    const resetTimer = ( delay: number = TIMER ) => {
        if ( timeoutRef.current ) {
            clearTimeout( timeoutRef.current );
        }

        timeoutRef.current = setTimeout( () => {
            const availableIndices = currentPuns
                .map( ( _, index ) => index )
                .filter( ( index ) => !punsAlreadyVoted.includes( index ) );

            let nextIndex;

            if ( availableIndices.length > 1 ) {
                // If there's more than one index available, remove the current index
                const filteredIndices = availableIndices.filter( ( index ) => index !== punIndex );

                nextIndex = filteredIndices[Math.floor( Math.random() * filteredIndices.length )];
            } else if ( availableIndices.length === 1 ) {
                // only one index is available
                nextIndex = availableIndices[0] === punIndex
                    ? ( punIndex + 1 ) % currentPuns.length
                    : availableIndices[0];
            } else {
                // select the next pun in a cyclic manner.
                nextIndex = ( punIndex + 1 ) % currentPuns.length;
            }

            console.log( 'nextIndex', nextIndex );
            setPunIndex( nextIndex );
        }, delay );
    };

    const onClick = ( index: number ) => {
        if ( onVoteClick ) {
            onVoteClick( index );

            if ( !punsAlreadyVoted.includes( index ) ) {
                setPunsAlreadyVoted( [...punsAlreadyVoted, index] );
            }

            // Reset the timer when the user votes
            resetTimer( config.hero.timerAfterVote );
        }
    };

    const animationFinished = ( isDone: boolean ) => {
        if ( isDone ) {
            resetTimer();
        }
    };

    useEffect( () => {
        if ( slide.type === 'vote' && slide?.options && slide.options.length > 0 ) {
            setCurrentPuns( slide.options );
        }
    }, [slide] );

    useEffect( () => {
        if ( slide.type === 'vote' ) {
            if ( punsAlreadyVoted.includes( punIndex ) ) {
                setThanks( true );
            } else {
                setThanks( false );
            }
        }
    }, [punsAlreadyVoted, punIndex] );

    useEffect( () => () => {
        if ( timeoutRef.current ) {
            clearTimeout( timeoutRef.current );
        }
    }, [] );

    const videoStyle: CSSProperties = {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: isMobile ? '10% 50%' : '50% 50%',
    };

    return (
        <div
          className="slider__wrapper__hero__slide"
          style={
                    {
                        maxWidth: '100%',
                        width: '100%',
                    }
                }
            >
            {
                    slide?.image && !slide?.video && (
                        <img width="100%" src={ `images/Sliders/Hero/${slide.image}.webp` } alt={ slide.title } />
                    )
            }
            {
                slide?.video && (
                    <>
                        <video
                          width="120%"
                          style={ videoStyle }
                          playsInline
                          autoPlay
                          muted
                          loop
                          poster={ `images/Sliders/Hero/${slide.image}` }
                          src={ `videos/${slide.video}.mp4` }>
                            <source src={ `videos/${slide.video}.mp4` } type="video/mp4" />
                            <source src={ `videos/${slide.video}.webm` } type="video/webm" />
                            You can&lsquo;t read the video on this browser.
                        </video>
                        <div className="video-overlay" />
                    </>
                )
            }
            <div
              style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        color: 'white',
                    }
                }
              className="slider__wrapper__hero__slide__content"
                >
                <Grow in>
                    <h1
                      style={
                            {
                                margin: '0',
                                color: 'white',
                                fontSize: isMobile ? '3rem' : '3.5rem',
                                textAlign: isMobile ? 'left' : 'center',
                                lineHeight: isMobile ? '3rem' : 'inherit',
                            }
                        }
                    >
                        {
                                slide?.title && (
                                    slide.title
                                )
                            }
                    </h1>
                </Grow>
                {
                    slide?.type === 'vote' && punIndex !== null && currentPuns[punIndex] && (
                        <>
                            <h2
                              style={
                                    {
                                        textTransform: 'none',
                                        fontSize: isMobile ? '1.5rem' : '2rem',
                                        margin: isMobile ? '3rem 0 0 0' : 0,
                                        lineHeight: isMobile ? '2.5rem' : 'inherit',
                                        // fontWeight: isMobile ? 'lighter' : '',
                                        textAlign: isMobile ? 'left' : 'center',
                                        color: 'white',
                                    }
                                }
                            >
                                <StringFancyDisplay isDone={ animationFinished } key={ currentPuns[punIndex] } endFlash text={ `${currentPuns[punIndex]}*` } speed={ 75 } />
                            </h2>
                            <div className="slider__vote__container">
                                <span>
                                    *
                                    { config.hero.voteSentence }
                                </span>
                                <div className="slider__vote__thumbs">
                                    {
                                        !thanks ? (
                                            <>
                                                <img
                                                  onClick={ () => onClick( punIndex ) }
                                                  src="images/Sliders/t-u.png"
                                                  alt=""
                                                  role="button"
                                                  tabIndex={ 0 }
                                                  aria-label="Description of action or image"
                                                />
                                                <img
                                                  onClick={ () => onClick( punIndex ) }
                                                  src="images/Sliders/t-d.png"
                                                  alt=""
                                                  role="button"
                                                  tabIndex={ 0 }
                                                  aria-label="Description of action or image"
                                                />
                                            </>
                                            )
                                            : (
                                                <Grow in timeout={ 2000 }>
                                                    <span
                                                      style={
                                                            {
                                                                color: '#8152ff',
                                                            }
                                                        }
                                                    >
                                                        Thanks!
                                                    </span>
                                                </Grow>
                                            )
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
                {
                        slide?.subtitle && (
                            <Grow in timeout={ 2000 }>
                                <p>{ slide.subtitle }</p>
                            </Grow>
                        )
                    }
            </div>
        </div>
    );
}
