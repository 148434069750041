/* eslint-disable import/no-unresolved */
import React from 'react';
import { IPunContext } from 'PunContext';

const PunContext = React.createContext<IPunContext>( {
    punsAlreadyVoted: [],
    setPunsAlreadyVoted: () => {},
} );

export default PunContext;
