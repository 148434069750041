export const smoothScrollTo = (
    element: HTMLElement,
    startPosition: number,
    endPosition: number,
    duration: number,
): void => {
    let programmaticScroll = false;

    let animationFrameId: number | null = null;
    let startTime: number | null = null;
    let userScrolled = false;

    const handleUserScroll = () => {
        if ( programmaticScroll ) {
            programmaticScroll = false;
            return;
        }

        userScrolled = true;
        if ( animationFrameId !== null ) {
            cancelAnimationFrame( animationFrameId );
        }
    };

    window.addEventListener( 'scroll', handleUserScroll );

    function easeInOutQuad( t: number ): number {
        return t < 0.5 ? 2 * t * t : -1 + ( 4 - 2 * t ) * t;
    }

    function animation( currentTime: number ): void {
        if ( userScrolled ) {
            window.removeEventListener( 'scroll', handleUserScroll );
            return;
        }

        if ( startTime === null ) {
            startTime = currentTime;
        }

        const timeElapsed = currentTime - startTime;
        const progress = Math.min( timeElapsed / duration, 1 );
        const distanceMoved = easeInOutQuad( progress ) * ( endPosition - startPosition );

        programmaticScroll = true;

        window.scrollTo( 0, startPosition + distanceMoved );
        window.scrollTo( 0, startPosition + distanceMoved );

        if ( timeElapsed < duration ) {
            animationFrameId = requestAnimationFrame( animation );
        } else {
            window.removeEventListener( 'scroll', handleUserScroll );
        }
    }

    requestAnimationFrame( animation );
};
