import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import {
    BrowserRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import copies from './components/lib/copies.json';
import theme from './components/themes/default/theme';
import { Homepage } from './components/pages/Homepage';
import { NavigationBar } from './components/organisms/NavigationBar/NavigationBar';
import { Footer } from './components/organisms/Footer/Footer';
import './App.css';
import MobileContext from './contexts/MobileContext';
import { SystemPage } from './components/pages/SystemPages/SystemPage';
import NavbarContext from './contexts/NavbarContext';
import PunContext from './contexts/PunContext';

function App() {
    const [isMobile, setIsMobile] = React.useState( false );
    const [shouldShowNavigationBar, setShouldShowNavigationBar] = useState( false );
    const [mobileMenuOpen, setMobileMenuOpen] = useState( false );
    const [punsAlreadyVoted, setPunsAlreadyVoted] = useState<number[]>( [] );

    React.useEffect( () => {
        if ( window.innerWidth < 821 ) {
            setIsMobile( true );
        }
    }, [] );

    const contextValue = React.useMemo( () => ( { isMobile, setIsMobile } ), [isMobile] );
    const navBarContextValue = React.useMemo( () => ( { mobileMenuOpen, setMobileMenuOpen, shouldShowNavigationBar } ), [mobileMenuOpen, shouldShowNavigationBar] );
    const punContextValue = React.useMemo( () => ( { punsAlreadyVoted, setPunsAlreadyVoted } ), [punsAlreadyVoted] );

    useEffect( () => {
        if ( window.location.pathname === '/' ) {
            setShouldShowNavigationBar( true );
        } else {
            setShouldShowNavigationBar( false );
        }
    }, [window.location.pathname] );

    React.useEffect( () => {
        const handleResize = () => {
            if ( window.innerWidth < 821 ) {
                setIsMobile( true );
            } else {
                setIsMobile( false );
            }
        };

        window.addEventListener( 'resize', handleResize );

        return () => {
            window.removeEventListener( 'resize', handleResize );
        };
    }, [] );

    return (
        <Router>
            <HelmetProvider>
                <Helmet>
                    <title>{ copies.meta.title }</title>
                    <meta name="title" content={ copies.meta.title } />
                    <meta name="description" content={ copies.meta.description } />
                    <meta property="og:image" content={ `${copies.meta.image}` } />
                </Helmet>
                <ThemeProvider theme={ theme }>
                    <MobileContext.Provider value={ contextValue }>
                        <PunContext.Provider value={ punContextValue }>
                            <CssBaseline />
                            <div
                              style={
                            {
                                backgroundColor: '#541FBC',
                                position: 'relative',
                            }
                        }
                              className="App">
                                <div
                                  style={
                                {
                                    position: 'relative',
                                }
                            }
                                  className="main__app__container"
                        >
                                    <NavbarContext.Provider value={ navBarContextValue }>
                                        <NavigationBar />
                                    </NavbarContext.Provider>
                                    <Routes>
                                        <Route path="/" element={ <Homepage /> } />
                                        <Route path="/cookie-notice" element={ <SystemPage type="cookie-notice" /> } />
                                        <Route path="/privacy-policy" element={ <SystemPage type="privacy-policy" /> } />
                                        <Route path="/terms-of-service" element={ <SystemPage type="terms-of-service" /> } />
                                        <Route path="*" element={ <Navigate to="/" replace /> } />
                                    </Routes>
                                    <Footer />
                                </div>
                            </div>
                        </PunContext.Provider>
                    </MobileContext.Provider>
                </ThemeProvider>
            </HelmetProvider>
        </Router>
    );
}

export default App;
