import React, { Fragment } from 'react';
import { Mission } from '../sections/Homepage/Mission/Mission';
import { Hero } from '../sections/Homepage/Hero/Hero';
import { AboutUsSection } from '../sections/Homepage/AboutUs/AboutUs';
import config from '../lib/config.json';
import { Team } from '../sections/Homepage/Team/Team';
import { Careers } from '../sections/Homepage/Careers/Careers';
import { Partners } from '../sections/Homepage/Partners/Partners';

export function Homepage() {
    return (
        <div className="homepage__container">
            <div
              style={
                    {
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }
                }
              className="homepage__wrapper"
            >
                <Hero />
                <Mission />
                <AboutUsSection />
                <Team />
                <Careers />
                <Partners />
            </div>
        </div>
    );
}
