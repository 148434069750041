import { Container } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import useResizeObserver from 'use-resize-observer';
import React, { useEffect, useRef, useState } from 'react';
import copies from '../../../lib/copies.json';

import './AboutUs.css';
import { AboutUsTile } from '../../../organisms/AboutUsTile/AboutUsTile';
import MobileContext from '../../../../contexts/MobileContext';
import { SectionHeader } from '../../../atoms/SectionHeader';

const { aboutUs } = copies;

export function AboutUsSection() {
    const { isMobile } = React.useContext( MobileContext );
    const [collapsed, setCollapsed] = useState( true );

    const defaultTiles = isMobile ? aboutUs.tiles.slice( 0, 2 ) : aboutUs.tiles;
    const expandedTiles = isMobile ? aboutUs.tiles.slice( 2 ) : [];
    const [, setContentHeight] = useState<number>( 0 );

    const contentRef = useRef<HTMLDivElement>( null );
    const { height } = useResizeObserver<HTMLDivElement>( { ref: contentRef } );
    const animationProps = useSpring( {
        maxHeight: collapsed ? '0px' : '2000px', // Change 2000px to a value that's sure to cover your content
        opacity: collapsed ? 0 : 1,
        config: { tension: 280, friction: 60 },
        overflow: 'hidden',
    } );

    useEffect( () => {
        if ( contentRef.current ) {
            setContentHeight( contentRef.current.scrollHeight );
        }
    }, [contentRef.current, expandedTiles] );

    useEffect( () => {
        if ( height !== undefined ) {
            setContentHeight( height );
        }
    }, [height] );

    return (
        <div
          id="culture"
          className="aboutus__wrapper"
          style={
                  {
                      width: '100%',
                      position: 'relative',
                      display: 'flex',
                      padding: '5vh 0 5vh 0',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundImage: isMobile ? 'url(/images/Aboutus/background-mobile.webp)' : 'url(/images/Aboutus/background.webp)',
                      backgroundSize: 'cover',
                      backgroundAttachment: isMobile ? 'scroll' : 'fixed',
                      backgroundPosition: isMobile ? '50% 50%' : 'center',
                  }
              }
            >

            <div className="aboutus__overlay" />

            <Container
              maxWidth={ false }
              sx={
                        {
                            height: '100%',
                            // width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                    }
                >
                <div
                  className="aboutus__container"
                  style={
                    {
                        height: '100%',
                        width: isMobile ? '100%' : '70%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: '3rem',
                    }
                  }
                >
                    <SectionHeader
                      textColor="#fff"
                      style={
                        {
                          width: isMobile ? '90%' : '90%',
                          textAlign: isMobile ? 'center' : 'left',
                          zIndex: 1,
                        }
                      }
                      textStyle={
                        {
                          color: '#fff',
                          textAlign: isMobile ? 'center' : 'center',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          fontSize: isMobile ? '2rem' : '3rem',
                          fontFamily: '"Montserrat", sans-serif',
                        }
                      }
                    >
                        { aboutUs.header }
                    </SectionHeader>
                    {
                        defaultTiles.map( ( tile, index ) => (
                            <AboutUsTile
                              key={ tile.header }
                              header={ tile.header }
                              paragraph={ tile.paragraph }
                              index={ index + 1 }
                              style={ {
                                flex: '1 0 calc(33.33% - 3rem)',
                                width: isMobile ? '100%!important' : '20%',
                                borderRadius: '30px',
                                maxWidth: isMobile ? '75%' : '90%',
                                backgroundColor: 'rgb(21 7 48 / 56%)',
                                padding: '1rem',
                                cursor: 'pointer',
                            } }
                            />
                        ) )
                    }
                </div>
            </Container>

            { isMobile && !collapsed && (
            <animated.div style={ animationProps }>
                <Container
                  ref={ contentRef }
                  maxWidth={ false }
                  sx={ {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    rowGap: '3rem',
                    margin: '3rem 0',
                    justifyContent: 'center',
                    alignItems: 'center',
                  } }
                >
                    {
                      expandedTiles.map( ( tile, index ) => (
                          <AboutUsTile
                            key={ tile.header }
                            header={ tile.header }
                            paragraph={ tile.paragraph }
                            index={ index + defaultTiles.length + 1 }
                            style={ {
                              flex: '1 0 calc(33.33% - 3rem)',
                              width: '100%',
                              maxWidth: isMobile ? '75%' : '90%',
                              borderRadius: '30px',
                              padding: '1rem',
                              cursor: 'pointer',
                              } }
                          />
                        ) )
                      }
                </Container>
            </animated.div>
            ) }

            {
              isMobile && (
              <div
                onClick={ () => setCollapsed( !collapsed ) }
                tabIndex={ 0 }
                role="button"
                style={
                  {
                    margin: '5vh 0 0 0',
                    zIndex: 1,
                  }
                }
                className="aboutus__expand__view__cta noselect"
                >
                  { collapsed ? <span>Expand View ↓</span> : <span>Collapse View ˄</span> }
              </div>
              )
            }
        </div>
    );
}
