/* eslint-disable import/no-unresolved */
import React from 'react';
import { IMobileContextType } from 'MobileContext';

const MobileContext = React.createContext<IMobileContextType>( {
    isMobile: false,
    setIsMobile: () => {},
} );

export default MobileContext;
