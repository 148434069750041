import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';

interface ISectionTitleProps {
    headerText?: string;
    textStyle?: React.CSSProperties;
    children?: React.ReactNode;
    textColor?: string;
    style?: React.CSSProperties;
}

export function SectionHeader( {
    children, headerText, textStyle, style,
}: ISectionTitleProps ) {
    const theme = useTheme();
    const [displayedtext, setDisplayedText] = React.useState( '' );

    useEffect( () => {
        if ( children ) {
            setDisplayedText( children as string );
        } else if ( headerText ) {
            setDisplayedText( headerText );
        }
    }, [children, headerText] );

    return (
        <div
          className="section__header__container"
          style={ {
                ...style,
            } }
            >
            <h2
              style={
                    {
                        ...textStyle,
                        color: textStyle?.color || theme.palette.primary.main,
                    }
                }
            >
                { displayedtext }
            </h2>
        </div>
    );
}
