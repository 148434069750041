import React from 'react';
import { Button } from '@mui/material';
import config from '../lib/config.json';

export interface ISocialIcons {
    name: string;
    url: string;
    icon: string;
}

export interface ISocialIconsProps {
    icons: ISocialIcons[];
    color?: string;
    widthIcons?: string;
    widthContainerIcon?: string;
    heightComponent?: string;
}

export function SocialIcons( {
    icons,
    color,
    widthIcons,
    widthContainerIcon,
    heightComponent,
}: ISocialIconsProps ) {
    return (
        <div className="socialicons__container">
            <div className="socialicons__wrapper">
                <div
                  style={
                        {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            height: heightComponent || '100%',
                            width: widthContainerIcon || '100%',
                        }
                    }
                  className="socialicons__icon">
                    { icons.map( ( icon ) => (
                        <div
                          key={ icon.name }
                          className="socialicons__icon__container"
                          style={ // horizontal menu bar
                                {
                                    height: '100%',
                                    width: widthIcons,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                }
                            }
                            >
                            <Button
                              sx={
                                    {
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                }
                            >
                                <a
                                  href={ icon.url }
                                  target="_blank"
                                  rel="noreferrer"
                                  style={
                                        {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                  }
                                  >
                                    <img
                                      src={ `images/SocialIcons/${icon.icon}` }
                                      alt={ icon.name }
                                      color="#ffffff"
                                      style={ {
                                        height: widthIcons || '32px',
                                        width: widthIcons || '32px',
                                        fill: color || '#ffffff!important',
                                        color: color || '#ffffff!important',
                                        } }
                                />
                                </a>
                            </Button>
                        </div>
                        ) ) }
                </div>
            </div>

        </div>
    );
}
