import React from 'react';
import { AmazingSlider } from '../../../organisms/AmazingSlider/AmazingSlider';

export function Hero() {
    return (
        <div style={ { width: '100%', height: '80vh' } } className="hero__container">
            <AmazingSlider />
        </div>
    );
}
