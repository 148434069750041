import React from 'react';
import { TeamViewer } from '../../../organisms/TeamViewer/TeamViewer';
import team from '../../../lib/team.json';

export function Team() {
    return (
        <div id="team" className="team__container">
            <TeamViewer short={ team.short } expanded={ team.expanded } alumni={ team.alumni } />
        </div>
    );
}
