/* eslint-disable import/no-unresolved */
import React from 'react';
import { IMemberCardProps } from 'TeamViewer';
import MobileContext from '../../../contexts/MobileContext';
import config from '../../lib/config.json';
import './PartnerTile.css';

const { team } = config;
const { maxNumberOfGames } = team;

interface IPartnerTileProps {
    partner: {
        name: string;
        title?: string;
        photo?: string;
        logo?:string;
        link?: string;
        type: string;
    };
    maxWidth?: number;
}

export function PartnerTile( { partner, maxWidth }: IPartnerTileProps ) {
    const { isMobile } = React.useContext( MobileContext );

    return (
        <div
          style={
              {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: '20px',
                  padding: '2vh 0.5%',
                  width: isMobile ? '100%' : '30%',
                  maxWidth: isMobile ? '100%' : maxWidth || 300,
                  background: 'linear-gradient(180deg, #907DFF 0%, #722BFF 100%)',
                  boxShadow: '0px 20px 40px 0px #00000040',
                  backgroundPosition: '50% 50%',
                  minHeight: isMobile ? 'undefined' : '300px',
                }
          }
          className="partner-tile__map__member img-shadow noselect"
          key={ partner.name }
            >
            <div
              style={
                {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }
            }
              className="partner-tile__map__wrapper"
            >
                { partner.type === 'person' && (
                    <>
                        <a
                          href={ partner.link }
                          target="_blank"
                          rel="noreferrer"
                          className="partner-tile__map__partner__wrapper__image"
                        >
                            <div style={ {
                                padding: '5px',
                                borderRadius: '50%',
                                display: 'flex',
                            } }>
                                <img
                                  style={ {
                                        width: maxWidth ? maxWidth / 2 : 150,
                                        height: maxWidth ? maxWidth / 2 : 150,
                                        borderRadius: '50%',
                                        filter: 'sepia(1) hue-rotate(222deg) brightness(0.9)',
                                    } }
                                  src={ `images/Partners/${partner.photo}.webp` }
                                  alt={ partner.name }
                        />
                            </div>
                        </a>
                        <h4
                          style={ {
                    margin: '1.5rem 0 0 0',
                  } }
                          className="aligncenter">
                            { partner.name }
                        </h4>
                        <span
                          style={ {
                    color: '#fff',
                    opacity: 0.6,
                } }
                          className="aligncenter"
                >
                            { partner.title }
                        </span>
                    </>
                ) }
                {
                    partner.type === 'company' && (
                        <a
                          href={ partner.link }
                          target="_blank"
                          rel="noreferrer"
                          className="partner-tile__map__partner__wrapper__image"
                        >
                            <img
                              width="80%"
                              style={
                                {
                                  maxWidth: '200px',
                                }
                              }
                              src={ `images/Partners/${partner.logo}.webp` }
                              alt={ partner.name }
                            />
                        </a>
                    )
                }
            </div>
        </div>
    );
}
