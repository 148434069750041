import { Container } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import copies from '../../../lib/copies.json';
import './Careers.css';
import { HarmonyCta } from '../../../molecules/Button/HarmonyCta';
import MobileContext from '../../../../contexts/MobileContext';
import config from '../../../lib/config.json';

export function Careers() {
    const { isMobile } = React.useContext( MobileContext );

    return (
        <div
          id="careers"
          style={
                {
                    // height: missionSection.height,
                    width: '100%',
                    padding: isMobile ? '5vh 0 5vh 0' : '10vh 0 10vh 0',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'linear-gradient(180deg, #FFFFFF 0%, #DDCCFF 100%)',
                    backgroundPosition: 'top',
                }
            }
            >
            <Container
              maxWidth={ false }
              sx={
                        {
                            height: '100%',
                            width: isMobile ? '100%' : '70%',
                            maxWidth: '90%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            flexDirection: isMobile ? 'column' : 'row',
                        }
                      }
              className="careers__container"
                >
                <div
                  className="careers__img__container"
                  style={
                    {
                        width: isMobile ? '100%' : '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'left',
                        flexDirection: 'column',
                    }
                }
                >
                    <div
                      style={
                            {
                            display: 'inline-block',
                            padding: '3px',
                            width: isMobile ? '100%' : '85%',
                            background: 'linear-gradient(180deg, rgba(140, 82, 255, 0.2) 0%, rgba(140, 82, 255, 0.4) 100%)',
                            borderRadius: '33px',
                            boxShadow: '0px 20px 40px 0px #00000066',
                            }
                        }
                        >
                        <img
                          src="/images/Careers/careers.webp"
                          width={ isMobile ? 'calc(90% - 6px)' : '100%' }
                          alt="careers"
                          style={
                            {
                                borderRadius: '30px',
                                filter: 'sepia(1) hue-rotate(222deg) brightness(0.9)',
                                display: 'block',
                                maxWidth: '100%',
                            }
                            }
                        />
                    </div>
                </div>
                <div
                  style={
                    {
                        height: '100%',
                        width: window.innerWidth < 821 ? '100%' : '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                    }
                  }
                  className="careers__text__container"
              >
                    <h2>{ copies.careers.header }</h2>
                    <ReactMarkdown remarkPlugins={ [remarkGfm] }>
                        { copies.careers.paragraph }
                    </ReactMarkdown>
                    <div>
                        <p>{ `${copies.careers.openRoles.header}:` }</p>
                        { config.careers.jobs.map( ( job ) => (
                            <ul>
                                <li>
                                    <strong>
                                        <a
                                          style={ {
                                            fontFamily: '"Montserrat", sans-serif',
                                            textAlign: 'left',
                                            color: '#541FBC',
                                            lineHeight: '2rem',
                                            fontSize: '12pt',
                                            textDecorationLine: 'underline',
                                          } }
                                          href={ job.url }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          >
                                            { job.title }
                                        </a>
                                    </strong>
                                </li>
                            </ul>
                        ) ) }
                    </div>
                    <div
                      style={ {
                            margin: '5vh 0', display: 'flex', width: '100%', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start',
                        } }
                      className="harmonycta">
                        <HarmonyCta link={ `mailto:${config.careers.mailto}` } text="Contact Us" width={ isMobile ? '100%' : '300px' } />
                    </div>
                </div>
            </Container>
        </div>
    );
}
